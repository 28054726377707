// Imports
@import "./css/normalize";
@import "./css/react-vis";

@font-face {
  font-family: "Dank Mono";
  font-display: swap;
  src: url("/static/fonts/DankMono-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mont Web";
  font-display: swap;
  src: url("/static/fonts/Mont-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Mont Web";
  font-display: swap;
  src: url("/static/fonts/Mont-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Elena";
  font-display: swap;
  src: url("/static/fonts/ElenaWebBasicRegular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Elena";
  font-display: swap;
  src: url("/static/fonts/ElenaWebBasicBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Spartan";
  font-display: swap;
  src: url("/static/fonts/leaguespartan-bold.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Elena";
  font-display: swap;
  src: url("/static/fonts/ElenaWebBasicRegularItalic.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Elena";
  font-display: swap;
  src: url("/static/fonts/ElenaWebBasicBoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

// Colours
$c-p: #ff8065;
$c-s: #0d3b6d;
$c-b: #555555;
$c-g: #aaaaaa;

// Fonts
$f-h: "Mont Web", sans-serif;
$f-r: "Elena", serif;
$f-c: "Dank Mono", monospace;

// Widths & Mobile Breakpoints
$b-c: 780px;
$b-mobile: 800px;
$b-tablet: 1200px;
$clip_angle: 4vw;

// Global

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;

  & > #__next {
    min-height: 100%;
    height: 100%;

    & > div {
      min-height: 100%;
      height: 100%;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  color: $c-b;
  font-family: $f-r;
  font-weight: 300;
  font-size: 1.35rem;
  font-weight: 200;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: $b-mobile) {
    font-size: 1.2rem;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.dark-bg {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $c-b;
}

b,
strong {
  font-weight: 600;
}

hr {
  margin: 80px auto;
  bottom: -68px;
  left: 40%;
  width: 20%;
  height: 5px;
  background: $c-p;
  border: none;
  transform: rotate(-3deg);
}

// React wrapping
.App,
#root {
  position: relative;
  height: 100%;
  min-height: 100%;
}

// Header

.header-wrap {
  height: 500px;
  z-index: 1;
}

// Home

.home-stack {
  position: fixed;
  height: 100%;
  width: 100%;
  min-height: 100%;
  background: $c-s;

  .top {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    padding-left: 2%;
    background: fade-out(darken($c-s, 10%), 0.8);
  }

  .middle {
    z-index: 3;
    position: absolute;
    bottom: 20%;
    left: 35%;

    h1 {
      color: #fff;
      margin-bottom: 0;
    }

    h3 {
      font-family: $f-r;
      color: $c-p;
      letter-spacing: normal;
      font-weight: normal;
      margin-top: 0;
      line-height: 1.3;
    }

    @media (max-width: $b-mobile) {
      left: 5%;
      bottom: 5%;
    }
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    text-align: right;
    font-family: $f-c;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    padding-right: 2%;
    line-height: 50px;
    color: $c-s;
    text-transform: uppercase;
    z-index: 3;

    @media (max-width: $b-mobile) {
      span {
        display: none;
      }
    }
  }
}

.flight-map-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20%;
  right: 0;
  z-index: 1;
  background-color: $c-p;
  background: linear-gradient(to right, $c-s, #ed6550);
  -webkit-mask-image: -webkit-linear-gradient(
    to right,
    rgba(0, 0, 0, 1) 50%,
    transparent 100%
  );

  @media (max-width: $b-mobile) {
    top: 25%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, $c-s, #ed6550);
    -webkit-mask-image: -webkit-linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 50%,
      transparent 100%
    );
  }
}

.portrait-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  background: #0c3562;
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), transparent);
  -webkit-mask-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 1),
    transparent 100%
  );

  img {
    position: fixed;
    left: 0;
    bottom: 0;
    height: auto;
    max-height: 100%;
    width: auto;
    max-width: 100%;
    z-index: 2;

    @media (max-width: $b-mobile) {
      top: 0;
      left: 0;
      right: 0;
      height: 70%;
      mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), #ffffff 90%);
      -webkit-mask-image: -webkit-linear-gradient(
        -45deg,
        rgba(0, 0, 0, 1),
        transparent
      );
    }
  }
}

// Typography

h1,
h2,
h3 {
  font-family: $f-h;
  letter-spacing: -0.07rem;
  font-weight: 700;
}

h2 {
  font-size: 3rem;
  line-height: 1.2;

  position: relative;

  b {
    font-weight: 600;
  }

  @media (max-width: $b-mobile) {
    font-size: 2rem;
  }
}

// Nav

.home-stack {
  nav {
    font-family: $f-h;
    text-transform: uppercase;
    z-index: 2;

    a {
      display: inline-block;
      font-size: 0.9rem;
      letter-spacing: 0.03rem;
      margin-right: 20px;
      font-weight: 700;
      text-decoration: none;
      color: $c-p;
      z-index: 3;
    }
  }
}

header.globalheader {
  position: relative;
  background: $c-p;
  z-index: 1;
  padding: 20px 0;

  nav {
    font-family: $f-h;
    text-transform: uppercase;
    z-index: 2;
    padding: 0 20px;

    a {
      display: inline-block;
      font-size: 0.9rem;
      letter-spacing: 0.03rem;
      margin-right: 20px;
      font-weight: 700;
      text-decoration: none;
      color: #fff;
      z-index: 3;
    }
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

// Content Global

.post-content,
.page-content,
.content {
  margin: 40px auto;
  background: #fff;
  z-index: 10;
}

.connect,
.content > *,
.page-body > *,
.post-body > *,
.post-body > pre > code.syntax {
  display: block;
  width: $b-c;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.connect {
  padding-bottom: 70px;
}

// Rules for content types

.page-body,
.post-body {
  h1,
  h2,
  h3 {
    margin-top: 6rem;
    margin-bottom: 0;

    @media (max-width: $b-mobile) {
      margin-top: 3rem;
    }
  }

  p,
  ul,
  ol,
  blockquote {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.page-body > img.size-large,
.post-body > img.size-large {
  width: 1200px;
  margin: 2rem auto;
}

.page-body > img.size-full,
.post-body > img.size-full {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin: 0rem 0;
  -webkit-clip-path: polygon(
    0 calc(0% + #{$clip_angle}),
    100% 0,
    100% calc(100% - #{$clip_angle}),
    0 100%
  );
  clip-path: polygon(
    0 calc(0% + #{$clip_angle}),
    100% 0,
    100% calc(100% - #{$clip_angle}),
    0 100%
  );
}

// Larger first paragraph

.post-body p:first-child {
  font-family: $f-h;
  position: relative;
  width: 900px;
  font-size: 1.7rem;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 140px;

  @media (max-width: $b-mobile) {
    font-size: 1.4rem;
  }

  em {
    font-style: normal;
  }

  &:after {
    position: absolute;
    content: "";
    bottom: -68px;
    left: 40%;
    width: 20%;
    height: 5px;
    background: $c-p;
    transform: rotate(-3deg);
  }
}

// Content Header

.post-content > .post-header {
  width: 1200px;
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6rem;
  margin-bottom: 3rem;

  h1 {
    font-family: $f-h;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: -0.16rem;
    @media (max-width: $b-mobile) {
      font-size: 3rem;
    }
  }
}

.post-meta {
  font-family: $f-c;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-size: 1rem;
  color: $c-g;

  .modified {
    color: $c-p;
    font-weight: 400;
  }
}

.content a {
  color: $c-b;
  position: relative;
  text-decoration: none;
  z-index: 1;

  &:before {
    position: absolute;
    left: 0;
    bottom: 2px;
    right: 0;
    content: "";
    border-bottom: solid 5px fade-out($c-p, 0.6);
    z-index: -1;
  }
}

ul.technologies {
  padding-left: 0;

  li {
    display: inline-block;
    padding: 2px 6px;
    margin-right: 5px;
    margin-bottom: 5px;

    &.f {
      color: $c-p;
      background: fade-out($c-p, 0.8);
    }

    &.b {
      color: $c-s;
      background: fade-out($c-s, 0.8);
    }
  }
}

// Charts

.d3-chart-wpshare {
  position: relative;
  height: 400px;
  font-family: $f-c;
  padding-right: 42px;
}

// WordPress Content

.wp-content {
  .aligncenter {
    margin-right: auto;
    margin-left: auto;
    display: block;
  }
}

// Syntax Highlighter

body code {
  position: relative;
  font-family: $f-c;
  top: -1px;
  font-size: 0.8em;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 2px 8px 4px;
  margin: 0 6px;
  font-weight: 400;
  color: $c-p;
}

.post-body > pre {
  width: auto;
  font-family: $f-c;
  max-width: 100%;
  background: #fafafa !important;
  margin: 40px 0;
  overflow-x: scroll;
}

.react-syntax-highlighter-line-number,
code.syntax {
  background: transparent;
  font-family: $f-c;
  font-size: 1rem;
  margin: 0;
  padding: 10px 0 30px;
  line-height: 1.6;
}

// Footer

.footer {
  margin-top: 140px;
  position: relative;
  padding: 80px 0;
  background: #ededed;
  color: #fff;
  z-index: 1;

  svg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 5vw;
    z-index: -1;
  }

  .footer-content {
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
  }

  .footer-block {
    border-radius: 10px;
    background: #fff;
    width: 300px;
    height: 300px;
    box-shadow: 0 20px 50px 0 fade-out(darken($c-p, 40%), 0.7);
  }
}

.credits {
  padding: 10px 0;
  background: $c-s;
  color: rgba(255, 255, 255, 0.8);
  font-family: $f-c;
  text-align: center;
  font-size: 1rem;

  a {
    color: #fff;
  }
}

$pubg: #f7c707;

.scout {
  font-family: "Spartan", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  background: #555;
  padding: 0;
  height: 100%;
  min-height: 100%;

  div[class^="header-"] {
    padding: 1% 1% 1% 4%;
  }

  .header-app {
    background: #333;
  }

  .header-section {
    background: linear-gradient(lighten($pubg, 5%), darken($pubg, 5%));
    color: #333;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    margin: 1.5% 0;
  }

  .player {
    display: flex;
    border-radius: 4px;
    padding: 2% 3%;
    background: #333;
    margin: 1% 1% 0 1%;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);

    &:last-child {
      margin-bottom: 1%;
    }

    &.streamer {
      background: #6441a4;
    }

    .score {
      display: flex;
      flex-direction: column;

      .loading {
        height: 28px;
        animation: pulse 1s infinite ease-in-out;
        border-radius: 4px;
      }

      .complete {
        opacity: 1;
        animation-name: fadeIn;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 400ms;
      }

      > div:nth-child(2) {
        text-transform: uppercase;
        color: #f7c707;
        font-size: 9px;
        letter-spacing: 0.05rem;
      }
    }
    .name {
      color: #f7c707;
      font-weight: bold;
      flex: 30% 1 1;
    }
    .kdr {
      flex: 10% 1 1;
    }
    .adr {
      flex: 10% 1 1;
    }
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// About Me

.block-dark {
  position: relative;
  overflow: hidden;
  width: 110%;
  background: $c-s;
  color: #fff;
  -webkit-clip-path: polygon(
    0 calc(0% + #{$clip_angle}),
    100% 0,
    100% calc(100% - #{$clip_angle}),
    0 100%
  );
  clip-path: polygon(
    0 calc(0% + #{$clip_angle}),
    100% 0,
    100% calc(100% - #{$clip_angle}),
    0 100%
  );

  a {
    color: $c-p;
  }
}

.profile-list {
  width: 1200px;
  max-width: 100%;
  padding: 120px 0;
  margin: 0 auto;
  columns: 3;
  font-family: $f-h;
  font-size: 1rem;
  font-weight: 400;
  line-height: 2;

  h2 {
    font-size: 2rem;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  @media (max-width: $b-tablet) {
    width: 100%;
    columns: 2;
  }

  @media (max-width: $b-mobile) {
    font-size: 0.9rem;
    columns: 1;

    ul {
      padding-left: 10px;
    }
  }
}
